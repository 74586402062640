import { memo, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { callApiAction } from "../../store/actions/commonAction"
import { Delete, Edit } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { createDepartment, deleteDepartment, fetchDepartments, updateDepartment } from "../../apis/department.api"
import MessageDilog from "../../components/texts/MessageDilog"
import { deleteTypeofSales, fetchTypeofSaless, updateTypeofSales } from "../../apis/typeofsale.api"
import CategoryTypeCreateController from "./CategoryTypeCreateController"
import CategoryTypeMainUi from "./CategoryTypeMainUi"
import { deleteCategoryApi, fetchCategoryApi, updateCategoryApi } from "../../apis/category.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { fetchCategoryAction } from "../../store/actions/setting.Action"

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()
    const modalkey = "delete"
    const [loading, setLoading] = useState(false)
    const { settings } = useSelector((state) => state)


    // const id = 'update';
    const onEdit = () => {
        dispatch(openModal(<CategoryTypeCreateController id={params._id}
        //      callBack={(response, updatedData) => {
        //     setParams({ ...params, ...updatedData })
        // }}
        />, "sm", false, "updatetypofsales"))
    }
        

    // const deleteFun = async () => {
    //     setLoading(true)
    //     dispatch(callApiAction(
    //         async () => await deleteTypeofSales({ id: params._id }),
    //         (response) => {
    //             setParams({})
    //             setLoading(false)
    //         },
    //         (err) => {
    //             setLoading(false)
    //         }
    //     ))
    //     dispatch(closeModal("messagedialogdeletee"))


    // }

    const deleteFun = async (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteCategoryApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
                dispatch(callSnackBar(params.name + " Deleted Successfully", SNACK_BAR_VARIETNS.suceess))
                dispatch(fetchCategoryAction(settings.category_filters))

                dispatch(closeModal("messagedialog"))
            },
            (err) => {
                setLoading(false)
            }
        ))


    }

    const onDelete = () => {
        
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" modalId="messagedialog" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm",false, "messagedialog"))
    }
    

    // const onDelete = () => {
    //     dispatch
    // }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        <IconButton 
        disabled={loading}
         size="inherit" 
        onClick={onDelete}
        >
            <Delete color="error" fontSize="inherit" />
        </IconButton>

    </Box>
})





const CategoryTypeMainController = () => {
    const dispatch = useDispatch()
    const title = "Type Of sale"
    const fetchApi = fetchCategoryApi
    const deleteApi = deleteCategoryApi
    const updateApi = updateCategoryApi
    const { settings } = useSelector((state) => state)


    const columns = useMemo(() => [

        { id: 1, fieldName: 'type', label: 'Category', align: "left", sort: true, renderValue: (params, setParams) => <Typography textTransform="capitalize">{params.name}</Typography> },
        // { id: 2, fieldName: 'has_a_distributor', label: 'Has a Destributor', align: "left", sort: true, renderValue: (params, setParams) => <Typography textTransform="capitalize">{params.has_a_distributor ? "Yes" : "No"}</Typography> },
        {
            id: 5,
            fieldName: '',
            label: 'Action',
            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);
    


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: '',
        sortDirection: -1

    })

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const getCategoryList = () => {
        if (!settings.category_data || settings.category_data.length === 0 || JSON.stringify(filters) != JSON.stringify(settings.category_filters)) {
          dispatch(fetchCategoryAction(filters));
        }
      }
      useEffect(() => {
        getCategoryList()
    
      }, [filters])


    return (
        <>
            <CategoryTypeMainUi
                title={title}

                filters={filters}
                setFilters={setFilters}
                loading={settings.category_loading}
                list={settings.category_data}
                columns={columns}


            />

        </>
    )
}
export default CategoryTypeMainController