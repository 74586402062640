const MODULES = {
    DASHBOARD: 1,
    PRODUCT:2,
    VENDOR:3,
    USER:4,
    RAWMATERIALS:5,
    TYPEOFSALES:6,
    PRODUCTIONPLANNING:7,
    STOCK:5,
    CATEGORY:9,
    QUALITY_CHECK:8,
    BRAND:10,
    COLOR:11,
    PRODUCTTYPE:12,
    BATCHLIST:13,
    PRODUCTQC:14,
    
    
}


export default MODULES
