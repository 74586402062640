import { useDispatch, useSelector } from "react-redux"
import useValidate from "../../store/hooks/useValidator"
import { useParams } from "react-router-dom"
import { closeModal } from "../../store/actions/modalAction"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { callApiAction } from "../../store/actions/commonAction"
import { createDepartment, updateDepartment, fetchDepartments, getDepartmentByIdApi } from "../../apis/department.api"
import RawMaterialCreateUi from "./RawMaterialCreateUi"
import { AddRawMaterialApi, getRawMaterialByIdApi, updateRawMaterialApi } from "../../apis/rawMaterial.api"
import { fetchRawMaterialAction } from "../../store/actions/setting.Action"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"

const RawMaterialCreateController = ({ callBack = () => { }, id, isModal, handleAreaModalClose }) => {
    const validate = useValidate()
    const { settings } = useSelector((state) => state)
    const dispatch = useDispatch()
    const params = useParams()
    const title = "Raw-Material"

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        rawMaterial_code: '',
        lead_time: '',
        min_stock_quantity: '',
        remarks: '',
        role: '',
        current_stock:'',
        hsn_no:'',
        alternative_vendor:'',
        prefered_vendor:'',
        department:''
    })

    const [originalDocument, setOriginalDocument] = useState({})


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Raw Material',
        },
        {
            required: true,
            value: fields.rawMaterial_code,
            field: 'RawMaterial Code',
        },

        {
            
            value: fields.alternative_vendor,
            field: 'Alternative Vendor',
        },
        
    {
            value: fields.prefered_vendor,
            field: 'Prefered Vendor',
        },
        {
            required: true,
            value: fields.department,
            field: 'Department',
        },
       
        {
            required: true,
            value: fields.lead_time,
            field: 'lead time',
        },
        {
            required: true,
            value: fields.hsn_no,
            field: 'HSN No',
        },
        {
            required: false,
            value: fields.min_stock_quantity,
            field: 'min stock quantity',
        },
        // {
        //     required: false,
        //     value: fields.remarks,
        //     field: 'Remarks',
        // },
        {
            required: true,
            value: fields.role,
            field: 'role',
        }
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.name,
            field: 'Raw Material',
        },
        {
            required: true,
            value: fields.rawMaterial_code,
            field: 'RawMaterial Code',
        },
        {
            required: true,
            value: fields.lead_time,
            field: 'lead time',
        },
        {
            required: false,
            value: fields.min_stock_quantity,
            field: 'min stock quantity',
        },
        // {
        //     required: false,
        //     value: fields.remarks,
        //     field: 'Remarks',
        // }
    ]), [fields])


    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await AddRawMaterialApi({ ...fields, lead_time: fields.role === 'day' ? fields.lead_time : 7 * fields.lead_time }),
                    async (response) => {
                        // await 
                        // callBack(response)
                        setLoading(false)
                        dispatch(
                            callSnackBar(
                              "Raw Material Created Successfully",
                              SNACK_BAR_VARIETNS.suceess
                            )
                          );
                        dispatch(fetchRawMaterialAction(settings.rawMaterial_filters))
                        dispatch(closeModal("rawMaterial"))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate);
        const updatedData = { id };
    
        for (let field in originalDocument) {
          if (
            originalDocument[field] &&
            fields[field] &&
            fields[field] != originalDocument[field]
          ) {
            updatedData[field] = fields[field];
          }
        }
    
        if (validationResponse === true) {
          setLoading(true);
          dispatch(
            callApiAction(
              async () => await updateRawMaterialApi(updatedData),
              async (response) => {
                await callBack(response, updatedData);
                dispatch(fetchRawMaterialAction(settings.rawMaterial_filters))
                setLoading(false);
                dispatch(
                  callSnackBar(
                    "Raw Material Updated Successfully",
                    SNACK_BAR_VARIETNS.suceess
                  )
                );
                dispatch(closeModal("update"));
              },
              (err) => {
                setLoading(false);
                setFields({ ...fields, err });
              }
            )
          );
        } else {
          setFields({ ...fields, err: validationResponse });
        }
      };

    const fetchById = (id) => {
        setLoading(true);
        console.log("object id", id);
        dispatch(
          callApiAction(
            async () => await getRawMaterialByIdApi({ id }),
            async (response) => {
              setFields({ ...fields, ...response });
              setOriginalDocument(response);
              setLoading(false);
            },
            (err) => {
              setFields({ ...fields, err });
              setLoading(false);
            }
          )
        );
      };
    
      useEffect(() => {
        if (id) fetchById(id);
      }, [id]);


    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
        createFunction()
    }

    return <RawMaterialCreateUi
        title={title}
        isUpdate={id}
        loading={loading}
        fields={fields}
        onSubmit={onSubmit}
        setFields={setFields}
        isModal={isModal}
        handleAreaModalClose={handleAreaModalClose}
    />
}
export default memo(RawMaterialCreateController)