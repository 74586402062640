import React, { Children } from 'react'
import { AddShoppingCart, Dashboard, DashboardCustomize, Groups2Outlined, Settings, ShowChart } from "@mui/icons-material"
import SignInController from './pages/signin/SignInController'
import { Navigate } from 'react-router-dom'
import NotAllowedComponent from '../src/components/layouts/NotAllowedComponent'
import { getDefaultRedirect } from './utils/routinghelper'
import AppContainer from './components/layouts/common/AppContainer'
import MODULES from './utils/module.constant'
import SignInUI from './pages/signin/SignInUi'
import { USER_ROLES } from './utils/constants'
import PagenotFound from './components/layouts/PagenotFound'
import { Title } from '@mui/icons-material'
import DepartmentController from './pages/department/DepartmentController'
import ApartmentIcon from '@mui/icons-material/Apartment';
import VendorListController from './pages/vendor/VendorListController'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BentoIcon from '@mui/icons-material/Bento';
import CreateUserController from './pages/user/CreateUserController';
import RawMaterialMainController from './pages/raw material/RawMaterialMainController';
import ListController from './pages/user/UserLIstController';
import CreateProductUi from './pages/products/CreateProductUi'
import ProductListUi from './pages/products/ProductListUi'
import CreateProductController from './pages/products/CreateProductController'
import ProductListController from './pages/products/ProductListController'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TypeOfSalesMainController from './pages/type_of_sales/TypeOfSalesMainController'
import ProductionListController from './pages/Production/ProductionListController'
import LeadListController from './pages/leads/LeadListController'
import DashboardListController from './pages/dashboard/DashboardListController'
import CategoryTypeMainController from './pages/categories/CategoryTypeMainController'
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import QualityCheckListController from './pages/quality check/QualityCheckListController'
import RuleIcon from '@mui/icons-material/Rule';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ProductBrandController from './pages/productbrand/ProductBrandController'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductColorController from './pages/productcolor/ProductColorController'
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ProductTypeController from './pages/producttype/ProductTypeController'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BatchListController from './pages/quality check/BatchListController'
import AppearanceChecklistUi from './pages/quality check/AppearanceChecklistUi'
// import StepperListController from './pages/quality check/StepperListController'
// import StepperListController from './pages/quality check/StepperListController'
// import StepperListController from './pages/quality check/StepperListController.Jsx'


const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

  const obj = {
    hideInPannel,
    element: Component,
  }
  if (!login) {
    obj['element'] = <Navigate replace to={'/sign-in'} />
  } else {

    let found = false
    for (let module of allowed) {
      for (let allowedModule of permittedModule) {
        if (module == allowedModule) {
          found = true
          break;
        }
      }
    }
    if (!found) {
      obj['hideInPannel'] = true
      obj['element'] = <NotAllowedComponent />
    }

  }
  return obj
}


const defineRoutes = (user) => {
  const allowedModules = user.data.modules ?? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
  const defaultRedirect = getDefaultRedirect(allowedModules)
  return ([
    {
      path: "sign-in",
      element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to={defaultRedirect} />,
      hideInPannel: true

    },
    {
      path: "",
      element: user.isLoggedIn ? <Navigate replace to={defaultRedirect} /> : <Navigate replace to="/sign-in" />,
      hideInPannel: true

    },
    {
      path: "dashboard",
      icon: <DashboardCustomize />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <AppContainer ><DashboardListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,

    },

    {
      path: "products",
      icon: <AddShoppingCart />,
      title: "Products",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCT], allowedModules, <AppContainer ><ProductListController /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.PURCHASE,

    },

    {
      path: "raw-material",
      icon: <Inventory2Icon />,
      title: "Raw Material",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.RAWMATERIALS], allowedModules, <AppContainer ><RawMaterialMainController /></AppContainer>, defaultRedirect),
      hideInPannel: false,

    },
    {
      path: "stock",
      icon: <ShowChart />,
      title: "Stocks In/Out",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.STOCK], allowedModules, <AppContainer ><LeadListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,

    },

    // {
    //   path: "quality-check",
    //   icon: <AssignmentTurnedInIcon />,
    //   title: "Quality Check",
    //   ...loggedInPathElementRender(user.isLoggedIn, [MODULES.QUALITY_CHECK], allowedModules, <AppContainer ></AppContainer>, defaultRedirect),
    //   children: [
    //     {
    //       path: "",
    //       icon: RuleIcon,
    //       title: "Product Batch",
    //       ...loggedInPathElementRender(user.isLoggedIn, [MODULES.QUALITY_CHECK], allowedModules, <QualityCheckListController />, defaultRedirect),
    //       hideInPannel: false,
    //     },
    //     {
    //       path: "batch-list/:product_id/:batch_no",
    //       ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BATCHLIST], allowedModules, <BatchListController />, defaultRedirect),
    //       hideInPannel: true,
    //     },
    //     {
    //       path: "batch-list/:product_id/:batch_no/:serial_no",
    //       ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCTQC], allowedModules, <StepperListController />, defaultRedirect),
    //       hideInPannel: true,
    //     },

    //   ]
    // },

    {
      path: "production",
      icon: <PrecisionManufacturingIcon />,
      title: "Production Planning",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCTIONPLANNING], allowedModules, <AppContainer ><ProductionListController /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN || user.data.role === USER_ROLES.PRODUCTION ? false : true,

    },


    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <AppContainer ><ListController /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN ? false : true,

    },

    {
      path: "vendor",
      icon: <PersonSearchIcon />,
      title: "Vendor",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.VENDOR], allowedModules, <AppContainer ><VendorListController /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN || user.data.role === USER_ROLES.PURCHASE ? false : true,
    },

    {
      path: "settings",
      icon: <Settings />,
      title: "Settings",
      ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <AppContainer />, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN ? false : true,
      children: [

        {
          path: "department",
          icon: ApartmentIcon,
          title: "Department",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <DepartmentController />, defaultRedirect),
        },
        {
          path: "type_of_sales",
          icon: ChecklistIcon,
          title: "Type Of Sales",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.TYPEOFSALES], allowedModules, <TypeOfSalesMainController />, defaultRedirect),
        },
        {
          path: "category_type",
          icon: CategoryIcon,
          title: "Category",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.CATEGORY], allowedModules, <CategoryTypeMainController />, defaultRedirect),

        },
        {
          path: "product-brand",
          icon: ShoppingCartIcon,
          title: "Product Brand",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BRAND], allowedModules, <ProductBrandController />, defaultRedirect),
        },

        {
          path: "product-type",
          icon: FormatListBulletedIcon,
          title: "Product Type",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCTTYPE], allowedModules, <ProductTypeController />, defaultRedirect),
        },

        {
          path: "product-color",
          icon: ColorLensIcon,
          title: "Product Color",
          ...loggedInPathElementRender(user.isLoggedIn, [MODULES.COLOR], allowedModules, <ProductColorController />, defaultRedirect),
        },
      ]
    },

    {
      path: "*",
      hideInPannel: true,
      element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <PagenotFound />,
    },

  ])

}

export default defineRoutes