import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../components/tables/DataTable";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  FilterTitleBox,
  FiltersBox,
} from "../../components/layouts/OneViewBox";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import CreateProductController from "../products/CreateProductController";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import { fetchProductApi } from "../../apis/product.api";
import CustomInput from "../../components/inputs/CustomInputs";
import { findNameByRole, titleCase } from "../../utils/main";
import { DAY_WEEK_MONTH } from "../../utils/constants";
import { useState } from "react";

const ProductionListUi = ({
  columns,
  list,
  filters,
  setFilters,
  loading,
  title,
  fields,
  setFields,
  params,
  fetchList,
  setGenerateTable,
  generateTable,
}) => {
  const dispatch = useDispatch();
  const id = "product";

  const allowed = [
    ...Object.keys(DAY_WEEK_MONTH).map((key) => ({
      label: titleCase(key),
      _id: DAY_WEEK_MONTH[key],
    })),
  ];
  // const handleLeadtime = (e) => {

  //     setFields({
  //         ...fields,
  //         err: '',
  //         lead_time: e.target.value
  //     })
  // }

  const onCreateBtnClick = () => {
    dispatch(openModal(<CreateProductController />, "sm", false, id));
  };
  return (
    <>
      <Box>
        <Paper
          sx={{
            width: "100%",
            height: "85vh",
            padding: 6,
          }}
        >
          <Box
            mb={4}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <FilterTitleBox>
              <Box mt={2}>
                <Typography variant="h3" color={"#000"}>
                  {title}
                </Typography>
              </Box>

              <Box
                mb={2}
                sx={{
                  display: "flex",
                }}
              >
                <Box mr={2} />
              </Box>
            </FilterTitleBox>

            {
              <PaddingBoxInDesktop
                mt={5}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "39%" }}>
                  <AsyncDropDown
                    // defaultVal={
                    //     fields?.product_id
                    //         ? {

                    //             _id: fields?.product_id?._id,
                    //             name: fields?.product?.product_name,
                    //         }
                    //         : null
                    // }
                    lazyFun={async (para) =>
                      await fetchProductApi({ ...para, allStatus: true })
                    }
                    OptionComponent={({ option, ...rest }) => {
                      return (
                        <ListItem {...rest}>{option.product_name}</ListItem>
                      );
                    }}
                    value={filters?.product_id}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        product_id: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"product_name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Product*"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </Box>

                <Box width={"39%"}>
                  <CustomInput
                    disabled={loading}
                    value={fields.quantity}
                    onChange={(e) =>
                      setFields((prevFields) => ({
                        ...prevFields,
                        quantity: e.target.value,
                      }))
                    }
                    type="text"
                    label={"Quantity"}
                    sx={{ height: "56px" }}
                  />
                </Box>

                <Box width={"20%"}>
                  <Button
                    onClick={fetchList}
                    disabled={
                      !(
                        filters?.product_id &&
                        fields.quantity &&
                        fields.quantity > 0
                      )
                    }
                    sx={{
                      width: "100%",
                      height: "8vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="contained"
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        display: "flex",
                      }}
                    >
                      Generate Plan
                    </Typography>
                  </Button>
                </Box>


              </PaddingBoxInDesktop>
            }

            {generateTable == true && (
              <Box mt={5} width={"100%"} justifyContent={"center"}>
                <Box
                  sx={{
                    minHeight: "50vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <DataTable
                    columns={columns}
                    noPagination
                    rows={list.result ? list.result : []}
                    count={list?.total ?? 0}
                    filters={filters}
                    setFilters={setFilters}
                    loading={loading}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default ProductionListUi;



//  <Box sx={{ width: "24%" }}>
//                                 <Autocomplete
//                                     disableClearable
//                                     options={allowed}
//                                     value={findNameByRole(fields?.role)}
//                                     onChange={(e, newVal) => {
//                                         setFields({ ...fields, role: newVal ? newVal._id : null, parent_id: null })
//                                     }}
//                                     renderInput={(params) => <CustomInput placeholder="Select day/Week*" {...params} label="Select Day Or Week*" margin="dense" />}
//                                 />
//                             </Box>

//                             <Box width={"24%"}>
//                                 <CustomInput
//                                     disabled={loading}
//                                     value={fields?.lead_time}
//                                     onChange={(e) => handleLeadtime(e)}
//                                     type="number"
//                                     label={"Enter Time*"}
//                                 />
//                             </Box> 