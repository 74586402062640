import {
  Autocomplete,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import CustomInput from "../../components/inputs/CustomInputs";
import { closeModal } from "../../store/actions/modalAction";
import { AUS_STATES, USER_ROLES } from "../../utils/constants";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getOpenSolarUserApi } from "../../apis/user.api";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const ResetPasswordUi = ({
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const { user } = useSelector((state) => state);

  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${title}`}
        closeText="Close"
        confirmText={`Reset`}
      >
        <>
          {loading ? (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          ) : (
            <>
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.current_pass}
                onChange={(e) =>
                  setFields({
                    ...fields,
                    err: "",
                    current_pass: e.target.value,
                  })
                }
                type="text"
                label={"Old Password*"}
              />

              <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields.new_pass}
                onChange={(e) =>
                  setFields({ ...fields, err: "", new_pass: e.target.value })
                }
                type="text"
                label={"New Password*"}
              />
            </>
          )}

          {/* <DialogActions sx={{ padding: 0, mt: 3 }}>
            <SubmitButton title={"Close"} onClick={onClose}></SubmitButton>
            <SubmitButton
              onClick={onSubmit}
              loading={loading}
              title={"Reset"}
            ></SubmitButton>
          </DialogActions> */}
        </>
      </CustomDialog>
    </>
  );
};
export default memo(ResetPasswordUi);
