import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { Chip, Typography } from "@mui/material";
import { deleteProductApi } from "../../apis/product.api";
import {
  deleteRawMaterialApi,
  fetchRawMaterialApi,
} from "../../apis/rawMaterial.api";
import {
  fetchProductCurrentStock,
  fetchRawMaterialCurrentStock,
} from "../../apis/leads.api";
import { LOG_TYPE, USER_ROLES } from "../../utils/constants";
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/main";
import DashboardListUi from "../dashboard/DashboardListUi";

const LogTypeComponent = memo(({ params, setParams }) => {
  return (
    <Chip size="small" label={findObjectKeyByValue(params.logType, LOG_TYPE)} />
  );
});

const DashboardListController = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user)
  const initialItemType = user?.data?.role === USER_ROLES.ACCOUNT ? 'product' : 'rawMaterial';
  const [itemType, setItemType] = useState(initialItemType);
  const title = "Producttt";
  const fetchApi =
    itemType === "rawMaterial"
      ? fetchRawMaterialCurrentStock
      : fetchProductCurrentStock;

  const columns = useMemo(() => {
    if (itemType === "rawMaterial") {
      return [
        {
          id: 1,
          fieldName: "date",
          label: "Date",
          align: "left",
          sort: true,
          renderValue: (params) => moment(params.date).format("DD/MM/YYYY"),
        },
        {
          id: 7,
          fieldName: "rawMaterialId",
          label: "Raw Material Name",
          align: "left",
          sort: true,
          renderValue: (params) => params.rawMaterialId?.name,
        },

        {
          id: 4,
          fieldName: "quantity",
          label: "Quantity",
          align: "left",
          sort: true,
        },

        {
          id: 7,
          fieldName: "product_id",
          label: "Product Name",
          align: "left",
          sort: true,
          renderValue: (params) => params.product_id?.product_name,
        },
        {
          id: 3,
          fieldName: "product_code",
          label: "Product Code",
          align: "left",
          sort: true,
          renderValue: (params, setParams) => params.product_id?.product_code,
        },
        {
          id: 5,
          fieldName: "amount",
          label: "amount",
          align: "left",
          sort: true,
        },
        {
          id: 6,
          fieldName: "departmentId",
          label: "Department",
          align: "left",
          sort: true,
          renderValue: (params, setParams) => params.departmentId?.name,
        },
        {
          id: 8,
          fieldName: "logType",
          label: "Stock",
          align: "left",
          renderValue: (params, setParams) => (
            <LogTypeComponent onlyview params={params} setParams={setParams} />
          ),
        },
      ];
    } else {
      return [
        {
          id: 1,
          fieldName: "date",
          label: "Date",
          align: "left",
          sort: true,
          renderValue: (params) => moment(params.date).format("DD/MM/YYYY"),
        },
        {
          id: 7,
          fieldName: "product_id",
          label: "Product Name",
          align: "left",
          sort: true,
          renderValue: (params) => params.product_id?.product_name,
        },
        {
          id: 3,
          fieldName: "product_code",
          label: "Product Code",
          align: "left",
          sort: true,
          renderValue: (params, setParams) => params.product_id?.product_code,
        },
        {
          id: 4,
          fieldName: "quantity",
          label: "Quantity",
          align: "left",
          sort: true,
        },
        {
          id: 5,
          fieldName: "amount",
          label: "amount",
          align: "left",
          sort: true,
        },
        {
          id: 6,
          fieldName: "departmentId",
          label: "Department",
          align: "left",
          sort: true,
          renderValue: (params, setParams) => params.departmentId?.name,
        },

        {
          id: 8,
          fieldName: "logType",
          label: "Stock",
          align: "left",
          renderValue: (params, setParams) => (
            <LogTypeComponent onlyview params={params} setParams={setParams} />
          ),
        },
      ];
    }
  }, [itemType, dispatch]);

  const rawMaterial_columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Raw-Material Name",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "current_stock",
        label: "Current Stock",
        align: "left",
        sort: true,
          renderValue: (params) => {
            return (
                <Typography
                    backgroundColor={params.current_stock.quantity <= params.min_stock_quantity ? "rgba(255, 0, 0, 0.7)" : ""}
                    color={params.current_stock.quantity <= params.min_stock_quantity ? "white" : "black"}
                    display={"flex"}
                    width={"7vw"}
                    justifyContent={"center"}
                >
                    {params.current_stock.quantity}
                </Typography>
            );
        },
        // renderValue: (params, setParams) => params.current_stock.quantity,
      },
      {
        id: 7,
        fieldName: "name",
        label: "Category",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          params.current_stock?.category?.name,
      },

      {
        id: 3,
        fieldName: "lead_time",
        label: "Lead Time",
        align: "left",
        sort: true,
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "",
    sortDirection: -1,
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
  });
  const [rawMaterialFilters, setRawMaterialFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "",
    sortDirection: -1,
  });

  const handleItemTypeChange = (newItemType) => {
    setItemType(newItemType);
    setFilters((prevFilters) => ({
      ...prevFilters,
      pageNo: 1,
      searchable:
        newItemType === "product"
          ? ["product_id.product_name"]
          : ["rawMaterialId.name"],
    }));
  };
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  // useEffect(() => {
  //     setFilters(prevFilters => ({
  //         ...prevFilters,
  //         searchable: itemType === 'product' ? ['product_name'] : ['material_name'],
  //         logType: leadType === 'StockIn' ? LOG_TYPE.In : LOG_TYPE.Out
  //     }));
  // }, [leadType, itemType]);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const [rawMaterialList, setRawMaterialList] = useState({});

  const fetchRawMaterialList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchRawMaterialApi({ ...rawMaterialFilters }),
        (response) => {
          setRawMaterialList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
    fetchRawMaterialList();
  }, [filters, rawMaterialFilters]);

  return (
    <>
      <DashboardListUi
        title={title}
        filters={filters}
        setFilters={setFilters}
        rawMaterialFilters={rawMaterialFilters}
        setRawMaterialFilters={setRawMaterialFilters}
        loading={loading}
        list={list}
        rawMaterialList={rawMaterialList}
        rawMaterial_columns={rawMaterial_columns}
        columns={columns}
        itemType={itemType}
        setItemType={handleItemTypeChange}
      />
    </>
  );
};
export default DashboardListController;
